import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
} from "@material-ui/core"
import { Button } from "gatsby-theme-material-ui"
import { makeStyles } from "@material-ui/core/styles"
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { CardActionArea } from "gatsby-material-ui-components"

const useStyles = makeStyles(theme => ({
  actions: {
    padding: theme.spacing(0, 2, 2),
    justifyContent: "flex-end",
  },
  contentText: {
    "& p": {
      marginTop: 8,
      marginBottom: 8,
    },
    "& p:first-child": {
      marginTop: 0,
    },
    "& p:last-child": {
      marginBottom: 0,
    },
  },
}))

const CollectionCard = ({
  cardData: {
    frontmatter: { title, image, slug, url },
    html,
  },
}) => {
  const classes = useStyles()
  
  return (
    <Card variant="outlined" style={{ height: "100%" }}>
      <CardActionArea
        to={url ? null : `/collection/${slug}`}
        href={url ? url : null}
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        <div>
          {image && (
            <CardMedia title={title} style={{ position: "relative" }}>
              <GatsbyImage
                image={image.childImageSharp.gatsbyImageData}
                alt={title}
                style={{ height: "300px" }}
                objectPosition={"top"}
              />
              <CardHeader
                style={{
                  position: "absolute",
                  bottom: 0,
                  color: "#FFF",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  paddingTop: 8,
                  paddingBottom: 8,
                }}
                title={title}
              />
            </CardMedia>
          )}
          <CardContent style={{ padding: 16 }}>
            <div
              className={classes.contentText}
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </CardContent>
        </div>
        <CardActions classes={{ root: classes.actions }}>
          <Button variant="text" endIcon={<ArrowForwardIcon />}>
            Read more
          </Button>
        </CardActions>
      </CardActionArea>
    </Card>
  )
}

export default CollectionCard