import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Grid, Typography } from "@material-ui/core"
import SectionWrapper from "./SectionWrapper"
import CollectionCard from "./collectionCard"

const FeaturedSection = () => {
  const { allFile: { nodes } } = useStaticQuery(
    graphql`
      {
        allFile(
          filter: {sourceInstanceName: {eq: "collections"}}
          sort: {order: ASC, fields: name}
        ) {
          nodes {
            childMarkdownRemark {
              frontmatter {
                title
                slug
                image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
                url
              }
              html
            }
          }
        }
      }
    `
  )
  
  return (
    <SectionWrapper>
      <Typography variant="h2" color="primary">
        Featured Collections
      </Typography>
      <Grid container>
        {nodes.map(node => (
          <Grid key={node.childMarkdownRemark.frontmatter.slug} item xs={12} md={4}>
            <CollectionCard cardData={node.childMarkdownRemark} />
          </Grid>
        ))}
      </Grid>
    </SectionWrapper>
  )
}

export default FeaturedSection