import { Button, Box, InputAdornment, TextField } from "@material-ui/core"
import SearchIcon from "@material-ui/icons/Search"
import Autocomplete from "@material-ui/lab/Autocomplete"
import algoliasearch from "algoliasearch/lite"
import { navigate } from "gatsby"
import React, { useMemo } from "react"
import { connectAutoComplete, InstantSearch } from "react-instantsearch-dom"

const HomeSearch = () => {
  const searchClient = useMemo(
    () =>
      algoliasearch(
        process.env.GATSBY_ALGOLIA_APP_ID,
        process.env.GATSBY_ALGOLIA_SEARCH_KEY
      ),
    []
  )

  const SearchBox = connectAutoComplete(({ hits, refine, currentRefinement }) => {
    return (
      <Box display="flex" width="100%">
        <Autocomplete
          id="search-box"
          freeSolo
          onInputChange={(event, value) => {
            refine(value)
          }}
          onChange={(event, value, reason) =>
            navigate(`/search?query=${encodeURIComponent(value)}`)
          }
          options={hits.map(option => option.query)}
          fullWidth
          style={{ backgroundColor: "#FFF" }}
          renderInput={params => (
            <TextField
              id="search-input"
              {...params}
              placeholder="Search the archive"
              InputProps={{
                ...params.InputProps,
                type: "search",
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        <Button
          onClick={() =>
            navigate(`/search?query=${encodeURIComponent(currentRefinement)}`)
          }
          disabled={!currentRefinement}
        >
          Search
        </Button>
      </Box>
    )
  })

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={"sarrc_catalog_suggestions"}
    >
      <SearchBox />
    </InstantSearch>
  )
}

export default HomeSearch
