import React from "react"
import {useStaticQuery, graphql} from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Grid, Typography, Box } from "@material-ui/core"
import { ThemeProvider, createTheme } from "@material-ui/core/styles"
import SectionWrapper from "./SectionWrapper"
import theme from "../gatsby-theme-material-ui-top-layout/theme"
import { Button } from "gatsby-material-ui-components"
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"
import ArrowForwardIcon from "@material-ui/icons/ArrowForward"

const darkTheme = createTheme(theme, {
  palette: {
    text: {
      primary: "#fff",
    },
  },
  props: {
    MuiTypography: {
      color: "textPrimary",
    },
  },
})


const AboutSection = () => {
  const { allContentfulAboutSection } = useStaticQuery(
    graphql`
      query {
        allContentfulAboutSection {
          edges {
            node {
              title
              body {
                childMarkdownRemark {
                  html
                }
              }
              image {
                gatsbyImageData
              }
              backgroundImage {
                gatsbyImageData
              }
            }
          }
        }
      }
    `
  )

  const sectionContent = allContentfulAboutSection.edges[0].node
  const pluginImage = getImage(sectionContent.backgroundImage)

  return (
    <ThemeProvider theme={darkTheme}>
      <BgImage image={pluginImage}>
        <div style={{ backgroundColor: "rgba(208, 50, 0, 0.7)" }}>
          <SectionWrapper>
            <Grid container>
              <Grid item xs={12} md={2}>
                <Typography variant="h2" color="secondary">
                  {sectionContent.title}
                </Typography>
              </Grid>
              {/* <Grid item xs={false} md={1} /> */} 
              <Grid item xs={12} md={3}>
                <Typography
                  component="div"
                  dangerouslySetInnerHTML={{
                    __html: sectionContent.body.childMarkdownRemark.html,
                  }}
                />
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    to="/about"
                    endIcon={<ArrowForwardIcon />}
                    color="secondary"
                  >
                    Read more
                  </Button>
                </Box>
              </Grid>
              {/* <Grid item xs={false} md={1} /> */}
              <Grid item xs={12} md={7}>
                <Box
                  position="relative"
                  // mt={4}
                  pb={`${(9 / 16) * 100}%`}
                  height={0}
                >
                  <iframe
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                    }}
                    src={`https://www.youtube.com/embed/lpUhAdLkG3k?rel=0&modestbranding=1`}
                    title="YouTube video player"
                    frameBorder="0"
                    // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </Box>
                {/* <figure style={{margin: 0}}>
                  <GatsbyImage
                    image={sectionContent.image.gatsbyImageData}
                    alt={sectionContent.title}
                  />
                  <Box pt={1}>
                    <Typography variant="caption">ملت [milat]. October 13, 1979. Weekly Urdu Journal. Islamabad, 1978-’80.</Typography>
                  </Box>
                </figure> */}
              </Grid>
            </Grid>
          </SectionWrapper>
        </div>
      </BgImage>
    </ThemeProvider>
  )
}

export default AboutSection