import React from "react"
import { Grid, Typography, Box } from "@material-ui/core"
import { Button } from "gatsby-theme-material-ui"
import { makeStyles } from "@material-ui/core/styles"
import HomeSearch from "./search/homeSearch"
import { BgImage } from "gbimage-bridge"
import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"

const useStyles = makeStyles(theme => ({
  subtitle: {
    paddingTop: theme.spacing(5),
  },
  button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}))

const topSectionContent = {
  title: "South Asian Research & Resource Center",
  subtitle:
    "SARRC is an archive of documents and other materials preserving Pakistan’s progressive political history",
  buttons: [
    {label: "Box collections", param: "type", value: "Box"},
    { label: "Magazines", param: "type", value: "Magazine" },
    { label: "Books", param: "type", value: "Book" },
    { label: "Audio recording", param: "type", value:  "Audio Cassete" },
    { label: "Videos", param: "type", value: "Video Cassete" },
    { label: "Newspapers", param: "type", value: "Newspaper" },
    { label: "Cold War", param: "query", value: "cold war" },
    { label: "Ayub Era", param: "query", value: "ayub khan" },
    { label: "Zia Era", param: "query", value: "zia ul haq" },
    { label: "NAP", param: "query", value: "national awami party" },
    { label: "Communist Party", param: "query", value: "communist party" },
    { label: "Pakistan People’s Party", param: "query", value: "pakistan peoples party" },
    { label: "Mazdoor Kissan Party", param: "query", value: "mazdoor kissan party" },
    { label: "Sindh", param: "query", value: "sindh" },
    { label: "Punjab", param: "query", value: "punjab" },
    { label: "Balochistan", param: "query", value: "balochistan" },
    { label: "Khyber Pakhtunkhwa", param: "query", value: "khyber pakhtunkhwa" },
    { label: "Musharraf era", param: "query", value: "musharraf" },
  ],
}

const TopSection = () => {
  const classes = useStyles()
  const data = useStaticQuery(graphql`
    {
      file(name: { eq: "topsection-bg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `)

  return (
    <BgImage image={getImage(data.file)}>
      <section>
        <Grid container justifyContent="center" spacing={0}>
          <Grid item md={8}>
            <Box my={10} mx={2}>
              <Typography variant="h1" color="primary" align="center">
                {topSectionContent.title}
              </Typography>
              <Typography
                variant="h6"
                align="center"
                className={classes.subtitle}
              >
                {topSectionContent.subtitle}
              </Typography>
              <Box display="flex" justifyContent="center" my={3} bgcolor="#FFF">
                <HomeSearch />
              </Box>
              <Box pt={4} display={{xs: "none", md:"block"}}>
                <Typography variant="h3">Browse by category</Typography>
                <Box display="flex" flexWrap="wrap" justifyContent="center">
                  {topSectionContent.buttons.map(({ label, param, value }) => (
                    <Button
                      key={label}
                      to={
                        param === "query"
                          ? `search/?${param}=${encodeURIComponent(value)}`
                          : "/search"
                      }
                      state={param === "type" ? { type: value } : null}
                      style={{ margin: "4px" }}
                    >
                      {label}
                    </Button>
                  ))}
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </section>
    </BgImage>
  )
}

export default TopSection