import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import TopSection from "../components/topSection"
import FeaturedSection from "../components/featuredSection"
import AboutSection from "../components/aboutSection"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <TopSection />
    <FeaturedSection />
    <AboutSection />
    {/* <CatalogueSection /> */}
  </Layout>
)

export default IndexPage
